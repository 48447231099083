<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        产品中心
      </div>
      <div class="topic_desc_en">
        PRODUCT CENTER
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">
      <keep-alive :include="aliveComp">
        <component :detailInfo="detailInfo" :is="compName" @goDetail="goDetailFn" @goBack="compName='compList'"></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
import compList from "./comp/compList"
import compDetail1 from "./comp/compDetail1"
import compDetail2 from "./comp/compDetail2"
import compDetail3 from "./comp/compDetail3"
 
export default {
  components: {
    compList,
    compDetail1,
    compDetail2,
    compDetail3
  },
  // 定义属性
  data () {
    return {
      aliveComp:['compList'],
      compName:"compList",
      detailInfo:{},
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo () {
      // let lang = this.$i18n.locale;
      return "";
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取图片
    getPic(name){
      return require(`./images/${name}.png`)
    },
    goDetailFn(info){
      this.detailInfo = info;
      console.log(this.detailInfo)
      // this.detailInfo.category 如果为1，跳转到compDetail1，如果为2，跳转到compDetail2，如果为3，跳转到compDetail3
      if(this.detailInfo.category === '1'){
        this.compName = "compDetail1";
      }else if(this.detailInfo.category === '2'){
        this.compName = "compDetail2";
      }else if(this.detailInfo.category === '3'){
        this.compName = "compDetail3";
      }
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    let _this = this;
    _this.$EventBus.$off("resetRoute");
    _this.$EventBus.$on("resetRoute",()=>{
      _this.aliveComp = [];
      _this.compName = "";
      _this.$nextTick(()=>{
        _this.aliveComp = ['compList'];
        _this.compName = "compList";
      })
    });    
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 43px;
    .topic_desc{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #BCBDBE;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line{
      width: 76px;
      height: 3px;
      background: #0086D1;
    }
  }
  .main_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-bottom: 100px;
  }
}
</style>