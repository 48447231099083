<template>
  <div class="list_panel">
    <div class="item_info">
      <div class="item_title">{{ productsInfo.dbktLabel }}</div>
      <div
        class="item_desc"
        v-for="(item, index) in productsInfo.dbktDesc"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="list_content">
      <div
        :title="item.name"
        class="list_item"
        v-for="(item, idx) in dbktlistShow"
        :key="idx"
        @click="goDetailFn(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="item_info">
      <div class="item_title">{{ productsInfo.xbcpLabel }}</div>
      <div
        class="item_desc"
        v-for="(item, index) in productsInfo.xbcpDesc"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="list_content">
      <div
        :title="item.name"
        class="list_item"
        v-for="(item, idx) in xbcplistShow"
        :key="idx"
        @click="goDetailFn(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="item_info">
      <div class="item_title">{{ productsInfo.xqLabel }}</div>
      <div
        class="item_desc"
        v-for="(item, index) in productsInfo.xqDesc"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="list_content">
      <div
        :title="item.name"
        class="list_item"
        v-for="(item, idx) in xqlistShow"
        :key="idx"
        @click="goDetailFn(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name: "compList",
  components: {},
  // 定义属性
  data() {
    return {
      ktInfos: [],
      productsInfo: {},
      dbktlistShow: [],
      xbcplistShow: [],
      xqlistShow: [],
      currPage: 1,
      pageSize: 28,
    };
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goDetailFn(info) {
      this.$emit("goDetail", info);
    },
    async getProductsList1() {
      const { data } = await this.$http.get(
        `${window.SEVERURL}/safeglp/generator/products/listByType?currPage=${this.currPage}&limit=1000&category=1`
      );
      this.dbktlistShow = data.page.list;

      this.dbktlistShow.forEach((item) => {
        item.content = JSON.parse(item.content);
      });
      this.totalPage = data.page.totalPage;
    },
    async getProductsList2() {
      const { data } = await this.$http.get(
        `${window.SEVERURL}/safeglp/generator/products/listByType?currPage=${this.currPage}&limit=1000&category=2`
      );
      this.xbcplistShow = data.page.list;

      this.xbcplistShow.forEach((item) => {
        item.content = JSON.parse(item.content);
      });
      this.totalPage = data.page.totalPage;
    },
    async getProductsList3() {
      const { data } = await this.$http.get(
        `${window.SEVERURL}/safeglp/generator/products/listByType?currPage=${this.currPage}&limit=1000&category=3`
      );
      this.xqlistShow = data.page.list;

      this.xqlistShow.forEach((item) => {
        item.content = JSON.parse(item.content);
      });
      this.totalPage = data.page.totalPage;
    },
    getData() {
      let _this = this;
      _this.$http
        .get(
          `${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=productIntroduction`
        )
        .then((res) => {
          if (res.status == 200 && res.data.code == 0) {
            // 获取配置数据
            let jsonStr = res.data.page ? res.data.page.configs : "{}";
            let jsonStrPure = jsonStr.replace(/\n/g, "").replace(/\r/g, "");
            _this.productsInfo = JSON.parse(jsonStrPure);
            console.log(this.productsInfo);
          } else {
            _this.$notify({
              title: "失败",
              message: "产品信息获取失败",
              type: "error",
            });
          }
        });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getProductsList1();
    this.getProductsList2();
    this.getProductsList3();
    this.getData();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style scoped lang="scss">
.list_panel {
  .item_info {
    // 除了第一个item_info，其他的都有margin-top
    &:not(:first-child) {
      margin-top: 2.5rem;
    }
    .item_title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.2rem;
      color: #0086d1;
      line-height: 3.3125rem;
      margin-bottom: 0.5rem;
    }
    .item_desc {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 1rem;
      color: #333333;
      line-height: 1.8125rem;
      margin-bottom: 0.5rem;
    }
    .item_desc:last-child {
      margin-bottom: 1.75rem;
    }
  }
  width: 100%;
  .list_content {
    // display: flex;
    // flex-wrap: wrap;
    .list_item {
      width: 100%;
      height: 3rem;
      background: #f6f7f9;
      border-radius: 0px 0px 0px 0px;
      border-bottom: 1px solid #0086d1;
      line-height: 3rem;
      text-align: center;
      padding: 0 1.5rem;
      box-sizing: border-box;
      margin-bottom: 0.5rem;
      cursor: pointer;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI;
      font-weight: 400;
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        background: #0086d1;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: bold;
        font-size: 1.4rem;
        color: #ffffff;
      }
    }
  }
  .pagination_content {
    display: flex;
    justify-content: center;
  }
}
</style>
